import axios from "axios"

const axiosHelpers = {
  downloadFile({ url, method, filename } = { method: "GET" }) {
    return axios({
      url: url,
      method: method,
      responseType: "blob", // important
    }).then((response) => {
      const url = window.URL.createObjectURL(new Blob([response.data]))
      const link = document.createElement("a")
      link.href = url
      link.setAttribute("download", filename) //or any other extension
      document.body.appendChild(link)
      link.click()
    })
  },
}

export default axiosHelpers
